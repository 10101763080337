@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* orange-500 */
    --color-primary: 239 87 71;
    --color-primary-dark: 239 87 71;
    /* indigo-600 */
    --color-secondary: 79 70 229;
    --color-secondary-dark: 79 70 229;
  }
}